import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import useFlickity from '../hooks/useFlickity';

const Container = styled(Box)`
  padding: 80px 32px 100px;
  position: relative;
  background-color: #f4f3ef;

  @media (max-width: 750px) {
    padding: 48px 32px 32px;
  }

  @media (max-width: 600px) {
    padding: 32px;
  }

  .wrapper {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
  }

  .section-title {
    font-family: 'NoeDisplayRegular', Georgia, serif;
    font-weight: normal;
    font-size: 45px;
    line-height: 1;
    letter-spacing: 0.5px;
    margin-top: 0;
    margin-bottom: 60px;
    text-align: center;
    color: #143030;

    @media (max-width: 1000px) {
      font-size: 40px;
      letter-spacing: 0;
    }

    @media (max-width: 750px) {
      font-size: 36px;
      line-height: 1.2;
      margin-bottom: 40px;
      margin-top: 48px;
    }

    @media (max-width: 600px) {
      margin-bottom: 32px;
    }
  }

  .angels--desktop {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 16px;
    align-items: flex-start;

    @media (max-width: 850px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 750px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  .angels--mobile {
    display: none;

    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
    }

    .flickity {
      width: 100%;
      padding: 0;
      text-align: center;
    }

    .angel {
      padding: 0;

      .angel__inner {
        transition: transform 400ms cubic-bezier(0.17, 0.84, 0.44, 1);
      }

      &:not(.is-selected) {
        .angel__inner {
          transform: translateY(25px);
        }
      }

      img {
        width: 150px;
        max-width: 100%;
      }
    }

    .flickity-viewport {
      overflow: visible;
    }

    .angels-caption {
      text-align: center;
    }

    .caption__name {
      font-family: 'NoeDisplayRegularItalic', Georgia, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 1;
      letter-spacing: 1px;
      margin: 0 0 12px;
      color: #143030;
    }

    .caption__title {
      margin: 0;
      font-size: 10px;
      color: #979797;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
      max-width: 40ch;
      margin: 0 auto;
    }

    .swipe-callout {
      position: relative;
      color: #a59d94;
      margin-top: 16px;
      display: none;

      @media (max-width: 800px) {
        display: block;
      }

      span {
        font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
        text-transform: uppercase;
        margin: 0 16px;
        font-size: 11px;
        letter-spacing: 1.62px;
        text-align: center;
      }

      .arrow-icon {
        width: 8px;
        height: 8px;

        path {
          stroke: currentColor;
          stroke-width: 1px;
        }
      }
    }
  }

  .angel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    grid-column: span 2;
    margin-bottom: 24px;
    text-align: center;

    @media (max-width: 850px) {
      grid-column: unset;
      margin-bottom: 12px;
    }

    @media (max-width: 600px) {
      width: 60%;
    }

    &:first-of-type {
      grid-column: 2 / span 2;

      @media (max-width: 1000px) {
        grid-column: span 2;
      }

      @media (max-width: 850px) {
        grid-column: unset;
      }
    }

    &:nth-of-type(8) {
      grid-column: 2 / span 2;

      @media (max-width: 1000px) {
        grid-column: span 2;
      }

      @media (max-width: 850px) {
        grid-column: unset;
      }
    }

    &:nth-of-type(9) {
      @media (max-width: 1000px) {
        grid-column: 3 / span 2;
      }

      @media (max-width: 850px) {
        grid-column: unset;
      }
    }

    &:nth-of-type(10) {
      @media (max-width: 850px) {
        grid-column: 2;
      }

      @media (max-width: 750px) {
        grid-column: unset;
      }
    }

    img {
      width: 128px;
      max-width: 100%;
      margin-bottom: 28px;
    }

    h3 {
      font-family: 'NoeDisplayRegularItalic', Georgia, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 1;
      letter-spacing: 1px;
      margin: 0 0 12px;
      color: #143030;
    }

    p {
      margin: 0;
      font-size: 10px;
      color: #979797;
      text-transform: uppercase;
      letter-spacing: 1.4px;
      font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
    }
  }
`;

const ArrowIcon: React.FC<any> = ({ direction }) => {
  return (
    <svg
      className="arrow-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="9"
      viewBox="0 0 9 9"
      style={{
        transform: direction === 'right' ? `scaleX(-1)` : ``,
      }}
    >
      <path
        fill="none"
        stroke="#A59D94"
        strokeLinecap="square"
        d="M8,3.53553391 L1,3.53553391 M3.53553391,0 L-7.95585819e-13,3.53553391 L3.53553391,7.07106781"
        transform="translate(1 .964)"
      />
    </svg>
  );
};

const Angel: React.FC<any> = ({ angel, variant }) => {
  const { name, headshot, title } = angel;

  const item = {
    hidden: { y: 20, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1.2,
        ease: [0.17, 0.84, 0.44, 1],
      },
    },
  };

  return (
    <motion.div
      className="angel"
      variants={item}
      data-name={angel.name}
      data-title={angel.title}
    >
      <div className="angel__inner">
        <img src={headshot.fluid.src} alt={name} />
        {variant !== 'mobile' && (
          <>
            <h3>{name}</h3>
            <p>{title}</p>
          </>
        )}
      </div>
    </motion.div>
  );
};

const flickityOptions = {
  wrapAround: true,
  autoPlay: 5000,
  pageDots: false,
  prevNextButtons: false,
  selectedAttraction: 0.2,
  friction: 1,
};

const AngelsFeatured = ({ data }: any) => {
  const { angels_title, angels } = data;
  const [angelName, setAngelName] = useState(null);
  const [angelTitle, setAngelTitle] = useState(null);

  const { ref, flickity, flickityRef } = useFlickity(flickityOptions);

  useEffect(() => {
    if (!flickity.current) {
      return;
    }

    const handleSelect = function() {
      setAngelName((flickity.current?.selectedElement as any).dataset.name);
      setAngelTitle((flickity.current?.selectedElement as any).dataset.title);
    };

    // Update project name/slide count on slide update
    flickity?.current?.on('select', handleSelect);

    // Fix wrapAround bug not showing cloned element
    flickity.current?.select(1);
  }, []);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.08,
      },
    },
  };

  return (
    <Container>
      <div className="wrapper" ref={ref}>
        <h2 className="section-title">{angels_title}</h2>

        <div className="angels--mobile">
          <div className="flickity" ref={flickityRef}>
            {angels.map((angel: any, i: number) => (
              <Angel angel={angel} key={i} variant="mobile" />
            ))}
          </div>
          <div className="angels-caption">
            <div className="caption__name">{angelName}</div>
            <div className="caption__title">{angelTitle}</div>
          </div>

          <div className="swipe-callout">
            <ArrowIcon direction="left" />
            <span>Swipe</span>
            <ArrowIcon direction="right" />
          </div>
        </div>

        <InView triggerOnce rootMargin="-75px 0px">
          {({ inView, ref }) => (
            <>
              <div ref={ref} />
              <motion.ul
                className="angels--desktop"
                variants={container}
                initial="hidden"
                animate={inView ? 'show' : 'hidden'}
              >
                {angels.map((angel: any, i: number) => (
                  <Angel angel={angel} key={i} />
                ))}
              </motion.ul>
            </>
          )}
        </InView>
      </div>
    </Container>
  );
};

export default AngelsFeatured;
