import { motion } from 'framer-motion';
import React, { useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

const Container = styled(Box)`
  padding: 80px 32px 100px;
  position: relative;
  background-color: #f4f3ef;

  @media (max-width: 750px) {
    padding: 48px 32px 32px;
  }

  @media (max-width: 600px) {
    padding: 32px;
  }

  .section-title {
    font-family: 'NoeDisplayRegular', Georgia, serif;
    font-weight: normal;
    font-size: 45px;
    line-height: 1;
    letter-spacing: 0.5px;
    margin-bottom: 60px;
    text-align: center;
    color: #143030;
    margin-top: 0;

    @media (max-width: 1000px) {
      font-size: 40px;
      letter-spacing: 0;
    }

    @media (max-width: 700px) {
      font-size: 36px;
      line-height: 1.2;
      margin-bottom: 16px;
    }
  }

  .card-wrapper {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: center;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }
`;

const CardLink = styled(motion.a)`
  position: relative;
  width: 100%;
  max-width: 500px;
  background-color: #ffffff;
  border-radius: 8px;
  margin: 0 auto 16px;
  padding: 36px 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /* Hack to fix anti-aliasing bug in Chrome */
  transform: scale(0.99) translateZ(0);
  transition: transform 300ms cubic-bezier(0.65, 0.05, 0.36, 1),
    box-shadow 300ms cubic-bezier(0.65, 0.05, 0.36, 1);

  @media (min-width: 1000px) {
    margin-right: 24px;
    max-width: 420px;
  }

  @media (min-width: 1200px) {
    max-width: 420px;
    margin-right: 68px;
    padding: 36px 40px 16px;
  }

  &:last-of-type {
    @media (min-width: 900px) {
      margin-right: 0;
    }
  }

  &:hover {
    @media (min-width: 800px) {
      z-index: 10;
      transform: scale(1.05);
      box-shadow: 0 1.3px 0.8px rgba(0, 0, 0, 0.008),
        0 3.2px 2px rgba(0, 0, 0, 0.012), 0 6px 3.8px rgba(0, 0, 0, 0.015),
        0 10.7px 6.7px rgba(0, 0, 0, 0.018),
        0 20.1px 12.5px rgba(0, 0, 0, 0.022), 0 48px 30px rgba(0, 0, 0, 0.03);

      .card__title:after {
        transform: scaleX(1.25);
      }

      .card__button {
        border-color: #b37f57;
        color: #b37f57;
      }
    }
  }

  a {
    margin: 0;
  }

  .card__number {
    font-family: 'NoeDisplayRegular', Georgia, serif;
    font-size: 18px;
    text-align: center;
    color: #b37f57;

    &:after {
      content: '';
      width: 48px;
      height: 1px;
      background-color: #a59d94;
      display: block;
      opacity: 0.4;
      margin-top: 8px;
      margin-bottom: 16px;
      transition: all 300ms cubic-bezier(0.65, 0.05, 0.36, 1);
    }
  }

  .card__title {
    font-family: 'NoeDisplayRegular', Georgia, serif;
    font-size: 60px;
    font-weight: normal;
    line-height: 1;
    margin: 0 0 24px;
    color: #143030;

    @media (max-width: 1200px) {
      font-size: 50px;
    }
  }

  .card__description {
    font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
    font-size: 18px;
    line-height: 1.3;
    margin-top: 0;
    margin-bottom: 32px;
    text-align: center;
    max-width: 20ch;

    p {
      margin: 0;
    }
  }

  .card__button {
    width: 225px;
    height: 52px;
    border-radius: 55px;
    border: 1px solid #424143;
    line-height: 1;
    display: flex;
    align-items: center;
    padding-bottom: 3px;
    justify-content: center;
    margin-bottom: 24px;
    position: relative;
    overflow: hidden;
    transition: all 350ms cubic-bezier(0.65, 0.05, 0.36, 1);

    @media (max-width: 1000px) {
      width: auto;
      padding: 0 24px 3px;
      border-color: #b37f57;
      color: #b37f57;
    }

    @media (max-width: 400px) {
      transition: none;
    }

    &:hover {
      @media (min-width: 800px) {
        color: #ffffff !important;

        &:after {
          opacity: 1;
        }
      }
    }

    &:after {
      content: '';
      background-image: linear-gradient(219deg, #d69a6c 0%, #b37f57 100%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 350ms cubic-bezier(0.65, 0.05, 0.36, 1);
      z-index: -1;
      border-radius: 50px;
    }
  }
`;

const Card: React.FC<any> = props => {
  const { number, title, description, buttonText, url, uid, external } = props;
  const card = useRef(null);

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeInOut',
      },
    },
  };

  const cardContent = (
    <>
      <div className="card__number">{number}</div>
      <h3 className="card__title">{title}</h3>
      <div
        className="card__description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className="card__button">{buttonText}</div>
    </>
  );

  return (
    <>
      {external ? (
        <CardLink href={url} ref={card} variants={item} target="_blank">
          {cardContent}
        </CardLink>
      ) : (
        <CardLink ref={card} variants={item} href={`/${uid}`}>
          {cardContent}
        </CardLink>
      )}
    </>
  );
};

const AngelsHowItWorks: React.FC<any> = props => {
  const { how_it_works_title, steps } = props.data;

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        ease: 'easeInOut',
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <Container>
      <h2 className="section-title">{how_it_works_title}</h2>
      <InView triggerOnce rootMargin="-50px 0px">
        {({ inView, ref }) => (
          <>
            <div ref={ref}></div>
            <motion.ul
              className="card-wrapper"
              variants={container}
              initial="hidden"
              animate={inView ? 'show' : 'hidden'}
            >
              {steps.map((step: any, i: number) => (
                <Card
                  title={step.title}
                  description={step.body.html}
                  buttonText={step.button_text}
                  url={step.button_url.url}
                  uid={step.button_url.uid}
                  external={step.button_url.target}
                  number={`0${i + 1}`}
                  key={i}
                />
              ))}
            </motion.ul>
          </>
        )}
      </InView>
    </Container>
  );
};

export default AngelsHowItWorks;
