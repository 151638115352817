import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import { Box, Image } from 'rebass/styled-components';
import styled from 'styled-components';

import { isBrowser } from '../scripts/utils';
import angelsLogo from '../static/images/logos/angels-logo.svg';
import waves from '../static/images/textures/waves-transparent.png';

const HeroContainer = styled(Box)`
  width: 100%;
  height: 110vh;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 0;
`;

const heroMarginTop = 200;

const Hero = styled(Box)<any>`
  max-width: 1440px;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px;

  @media (max-width: 650px) {
    margin-top: 140px;
  }

  .hero__logo {
    width: 100px;
    margin-bottom: 10px;
    visibility: hidden;
  }

  .hero__title {
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    color: #143030;
    margin-bottom: 36px;

    @media (max-width: 650px) {
      margin-bottom: 20px;
    }

    p:first-of-type {
      font-size: 30px;
      line-height: 1;
      letter-spacing: 8px;
      margin-top: 0;
      margin-bottom: 10px;
      visibility: hidden;

      @media (max-width: 650px) {
        letter-spacing: 4px;
        font-size: 20px;
      }
    }

    p:last-of-type {
      font-size: 95px;
      line-height: 1;
      letter-spacing: 6px;
      margin: 0;
      visibility: hidden;

      @media (max-width: 650px) {
        font-size: 60px;
      }
    }
  }

  .hero__description {
    font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
    color: #143030;
    max-width: 40ch;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 1.8;
    margin-bottom: 48px;
    text-align: center;
    visibility: hidden;

    @media (max-width: 650px) {
      font-size: 15px;
      margin-bottom: 16px;
      max-width: 36ch;
    }

    strong {
      font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
      letter-spacing: -0.5px;
    }
  }

  .hero__button {
    width: 225px;
    height: 52px;
    border-radius: 55px;
    border: 1px solid #424143;
    line-height: 1;
    display: flex;
    align-items: center;
    padding-bottom: 3px;
    justify-content: center;
    margin-bottom: 24px;
    position: relative;
    overflow: hidden;
    visibility: hidden;
    transition: color 350ms cubic-bezier(0.65, 0.05, 0.36, 1),
      background-color 350ms cubic-bezier(0.65, 0.05, 0.36, 1);

    &:hover {
      color: #f4f3ef;
      background-color: #143030;
    }
  }
`;

const AngelsHero: React.FC<any> = ({ data }) => {
  const { hero_description, hero_button_text, hero_button_url } = data;

  const heroWrapper = useRef(null);
  const logo = useRef(null);
  const title1 = useRef(null);
  const title2 = useRef(null);
  const description = useRef(null);
  const button = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      gsap.registerPlugin(ScrollTrigger);
    }

    const tl = gsap.timeline({
      defaults: { ease: 'power2.inOut', duration: 0.8 },
    });

    tl.set(
      [
        logo.current,
        title1.current,
        title2.current,
        description.current,
        button.current,
      ],
      {
        opacity: 0,
      }
    );

    tl.from(logo.current, {
      y: 25,
      autoAlpha: 0,
      duration: 1.25,
      scale: 0.95,
      ease: 'power3.inOut',
    })
      .from(
        title1.current,
        {
          autoAlpha: 0,
          y: 10,
        },
        '<.7'
      )
      .from(
        title2.current,
        {
          autoAlpha: 0,
          y: 10,
        },
        '<.08'
      )
      .from(
        description.current,
        {
          autoAlpha: 0,
          y: 10,
        },
        '<.1'
      )
      .from(
        button.current,
        {
          autoAlpha: 0,
          y: 10,
        },
        '<.1'
      );
  }, []);

  return (
    <HeroContainer
      sx={{
        backgroundImage: ` url(${waves})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Hero mx="auto" ref={heroWrapper}>
        <Image
          src={angelsLogo}
          className="hero__logo"
          alt="Human First Angels Logo"
          ref={logo}
        />

        <h1 className="hero__title">
          <p ref={title1}>Human First</p>
          <p ref={title2}>Angels</p>
        </h1>
        <div
          className="hero__description"
          ref={description}
          dangerouslySetInnerHTML={{ __html: hero_description.html }}
        />
        <a
          href={hero_button_url.url}
          target={hero_button_url.target}
          className="hero__button"
          ref={button}
        >
          {hero_button_text}
        </a>
      </Hero>
    </HeroContainer>
  );
};

export default AngelsHero;
