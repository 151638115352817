import { motion } from 'framer-motion';
import React from 'react';
import { InView } from 'react-intersection-observer';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

const Container = styled(Box)`
  padding: 0 32px 100px;
  position: relative;
  background-color: #f4f3ef;

  @media (max-width: 500px) {
    padding: 0 32px 32px;
  }

  strong {
    font-family: 'FFMarkWebProMedium', 'Helvetica', Arial, sans-serif;
    font-weight: normal;
  }

  .wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 32px 48px;

    @media (max-width: 1400px) {
      padding: 0;
    }

    @media (max-width: 1000px) {
      display: block;
    }

    @media (max-width: 700px) {
      text-align: center;
    }
  }
`;

const Header = styled(Box)`
  text-align: center;
  margin-bottom: 72px;

  @media (max-width: 850px) {
    margin-bottom: 24px;
  }

  h2 {
    font-family: 'NoeDisplayRegular', Georgia, serif;
    font-size: 45px;
    line-height: 1.2;
    font-weight: normal;
    max-width: 30ch;
    margin: 0 auto 12px;

    @media (max-width: 1000px) {
      margin-bottom: 16px;
    }

    @media (max-width: 700px) {
      font-size: 32px;
      margin-bottom: 0;
      max-width: 450px;
      margin: 0 auto 16px;
    }

    em {
      font-family: 'NoeDisplayRegularItalic', Georgia, serif;
      font-style: normal;
      color: #b37f57;

      &:after {
        content: '';

        @media (max-width: 700px) {
          display: none;
        }
      }
    }

    p {
      margin: 0;
    }
  }

  .description {
    p {
      font-size: 20px;
      margin: 0;

      @media (max-width: 850px) {
        font-size: 16px;
      }
    }
  }
`;

const Deals = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0;

  @media (max-width: 850px) {
    display: block;
  }

  .deal {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    width: 30%;
    max-width: 330px;

    @media (max-width: 1000px) {
      font-size: 16px;
      line-height: 24px;
    }

    @media (max-width: 850px) {
      max-width: 450px;
      width: 100%;
      margin: 0 auto 24px;
    }

    &:before {
      content: '• • •';
      letter-spacing: 3px;
      color: #b37f57;
      margin-bottom: 16px;
      display: block;
      transition: opacity 300ms ease-in-out;

      @media (max-width: 850px) {
        margin-bottom: 24px;
      }
    }

    p {
      margin: 0;
    }
  }
`;

const Deal: React.FC<any> = ({ deal }) => {
  return (
    <motion.li
      className="deal"
      variants={{
        hidden: { y: 10, opacity: 0 },
        show: {
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: 'easeInOut',
          },
        },
      }}
    >
      <div
        className="deal__description"
        dangerouslySetInnerHTML={{ __html: deal.body.html }}
      />
    </motion.li>
  );
};

const AngelsDeals: React.FC<any> = ({ data }) => {
  return (
    <Container>
      <InView triggerOnce rootMargin="-50px 0px">
        {({ inView, ref }) => (
          <div className="wrapper" ref={ref}>
            <Header>
              <motion.h2
                dangerouslySetInnerHTML={{ __html: data.deals_title.html }}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: inView ? 0 : 10, opacity: inView ? 1 : 0 }}
                transition={{
                  ease: [0.68, 0.55, 0.27, 1],
                  duration: 0.5,
                }}
              />
              <motion.div
                className="description"
                dangerouslySetInnerHTML={{ __html: data.deals_subtitle.html }}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: inView ? 0 : 10, opacity: inView ? 1 : 0 }}
                transition={{
                  ease: [0.68, 0.55, 0.27, 1],
                  duration: 0.5,
                  delay: 0.05,
                }}
              />
            </Header>
            <Deals
              className="deals"
              variants={{
                hidden: { opacity: 0 },
                show: {
                  opacity: 1,
                  transition: {
                    ease: 'easeInOut',
                    staggerChildren: 0.2,
                    delayChildren: 0.15,
                  },
                },
              }}
              initial="hidden"
              animate={inView ? 'show' : 'hidden'}
            >
              {data.deals.map((deal: any, i: number) => (
                <Deal deal={deal} key={i} />
              ))}
            </Deals>
          </div>
        )}
      </InView>
    </Container>
  );
};

export default AngelsDeals;
