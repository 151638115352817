import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Box } from 'rebass/styled-components';
import { Helmet } from 'react-helmet';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/Layout';
import AngelsHero from '../components/AngelsHero';
import AngelsFeatured from '../components/AngelsFeatured';
import AngelsDeals from '../components/AngelsDeals';
import AngelsHowItWorks from '../components/AngelsHowItWorks';
import AngelsInvest from '../components/AngelsInvest';
import HITWFaqs from '../components/HITWFaqs';
import AngelsOverview from '../components/AngelsOverview';

const AngelsWrapper = styled(Box)`
  background-color: #f4f3ef;
`;

const HumanFirstAngels = ({ data }: any) => {
  const angelsData = data.allPrismicHumanFirstAngels.edges[0].node.data;

  return (
    <Layout headerBG="beige">
      <Helmet>
        <title>Human First Angels</title>
        <meta property="og:title" content="Human First Angels" />
        <meta
          property="og:description"
          content="A Human First community of angels and super angels who invest their money and time to help founders at the earliest stages alongside our venture fund."
        />
      </Helmet>
      <AngelsWrapper>
        <AngelsHero data={angelsData} />
        <AngelsOverview data={angelsData} />
        <AngelsDeals data={angelsData} />
        <AngelsFeatured data={angelsData} />
        <AngelsHowItWorks data={angelsData} />
        <AngelsInvest data={angelsData} />
        {angelsData.show_faq_section && (
          <HITWFaqs data={angelsData} variant="angels" />
        )}
      </AngelsWrapper>
    </Layout>
  );
};

export default withPrismicPreview(HumanFirstAngels);

export const query = graphql`
  query {
    allPrismicHumanFirstAngels {
      edges {
        node {
          data {
            page_title
            hero_description {
              html
            }
            hero_button_text
            hero_button_url {
              url
              target
            }
            definition {
              title
              subtitle
              pronunciation {
                fluid {
                  src
                }
                alt
              }
              body {
                html
              }
            }
            deals_title {
              html
            }
            deals_subtitle {
              html
            }
            deals {
              body {
                html
              }
            }
            angels_title
            angels {
              name
              title
              headshot {
                fluid {
                  src
                }
                alt
              }
            }
            how_it_works_title
            steps {
              title
              body {
                html
              }
              button_text
              button_url {
                url
                target
                uid
              }
            }
            invest_title
            invest_subtitle {
              html
            }
            companies {
              logo {
                fluid {
                  src
                }
                alt
              }
            }
            show_faq_section
            faq_title
            angels_faq {
              faq_title {
                html
              }
              faq_description {
                html
              }
            }
            angels_contact {
              html
            }
          }
          uid
        }
      }
    }
  }
`;
