import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';

import ModalClose from '../components/icons/ModalClose';
import Modal from '../components/Modal';
import useFlickity from '../hooks/useFlickity';

const Container = styled(Box)`
  padding: 80px 32px 100px;
  position: relative;
  background-color: #f4f3ef;

  @media (max-width: 750px) {
    padding: 48px 32px 32px;
  }

  @media (max-width: 600px) {
    padding: 32px;
  }

  .wrapper {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
  }

  .investors--mobile {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: none;

    @media (max-width: 600px) {
      display: block;
    }

    .investor {
      width: 100%;
    }
  }

  .investors--modal {
    width: 100%;
    padding: 100px 0 50px;
    margin: 0;
    overflow-y: auto;
    display: none;

    @media (max-width: 600px) {
      display: block;
    }

    &:before {
      content: '';
      width: 100%;
      height: 150px;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(244, 243, 239, 1),
        rgba(244, 243, 239, 0)
      );
      pointer-events: none;
    }

    .investor {
      margin: 0 auto 32px;
      width: 60%;
    }
  }

  .investors {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 16px;

    @media (max-width: 600px) {
      display: none;
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 950px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 700px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 16px;
    }
  }

  .investor {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    img {
      max-width: 100%;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

const Header = styled(Box)`
  margin-bottom: 60px;

  .section-title {
    font-family: 'NoeDisplayRegular', Georgia, serif;
    font-weight: normal;
    font-size: 45px;
    line-height: 1;
    letter-spacing: 0.5px;
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
    color: #143030;

    @media (max-width: 1000px) {
      font-size: 40px;
      letter-spacing: 0;
    }

    @media (max-width: 700px) {
      font-size: 36px;
      line-height: 1.2;
      margin-bottom: 16px;
    }
  }

  p {
    text-align: center;
  }
`;

const ViewAll = styled.button`
  text-align: center;
  appearance: none;
  border: 0;
  background: transparent;
  margin: 0 auto;
  font-family: 'FFMarkWebProLight', 'Helvetica', Arial, sans-serif;
  font-size: 16px;
  color: #424143;
  margin-top: 8px;
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`;

const ActionContainer = styled(Box)`
  position: absolute;
  top: 45px;
  right: 40px;
  width: 50px;
  height: 50px;
  z-index: 100;
  svg {
    cursor: pointer;
    transition: opacity 300ms ease-in-out;

    &:hover {
      opacity: 0.65;
    }
  }
  @media (max-width: 750px) {
    width: 30px;
    height: 30px;
    top: 25px;
    right: 25px;
  }
`;

const Investor: React.FC<any> = ({ investor }) => {
  const { logo } = investor;

  const item = {
    hidden: { y: 20, opacity: 0 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        ease: 'easeInOut',
      },
    },
  };

  return (
    <motion.div className="investor" variants={item}>
      <img src={logo.fluid.src} alt={`${logo.alt} logo`} />
    </motion.div>
  );
};

const flickityOptions = {
  wrapAround: true,
  autoPlay: 2000,
  pageDots: false,
  prevNextButtons: false,
  draggable: false,
  accessibility: false,
};

const container = {
  hidden: { opacity: 1 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const AngelsInvest = ({ data }: any) => {
  const { invest_title, invest_subtitle, companies } = data;
  const [open, setOpen] = useState(false);
  const { ref, flickityRef } = useFlickity(flickityOptions);

  const handleSelect = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <div className="wrapper" ref={ref}>
        <Header>
          <h2 className="section-title">{invest_title}</h2>
          <div dangerouslySetInnerHTML={{ __html: invest_subtitle.html }} />
        </Header>

        <div className="investors--mobile" ref={flickityRef}>
          {companies.map((investor: any, i: number) => (
            <Investor investor={investor} key={i} />
          ))}
        </div>
        <ViewAll onClick={handleSelect}>View all</ViewAll>

        <Modal key="modal" open={open} handleClose={handleClose} scroll={false}>
          <motion.ul
            className="investors--modal"
            variants={container}
            initial="hidden"
            animate={open ? 'show' : 'hidden'}
          >
            {companies.map((investor: any, i: number) => (
              <Investor investor={investor} key={i} />
            ))}
          </motion.ul>

          <ActionContainer onClick={() => setOpen(false)}>
            <ModalClose />
          </ActionContainer>
        </Modal>

        <InView triggerOnce rootMargin="-75px 0px">
          {({ inView, ref }) => (
            <>
              <div ref={ref} />
              <motion.ul
                className="investors"
                variants={container}
                initial="hidden"
                animate={inView ? 'show' : 'hidden'}
              >
                {companies.map((investor: any, i: number) => (
                  <Investor investor={investor} key={i} />
                ))}
              </motion.ul>
            </>
          )}
        </InView>
      </div>
    </Container>
  );
};

export default AngelsInvest;
