import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
import React, { forwardRef, useEffect, useRef } from 'react';
import { Box, BoxProps } from 'rebass/styled-components';
import styled from 'styled-components';

import { isBrowser } from '../scripts/utils';

const isMobile =
  isBrowser && window.matchMedia('screen and (max-width: 600px)').matches;

const Container = styled.div`
  margin-top: 100vh;
  height: 100%;
  background-color: #f4f3ef;
  overflow: visible !important;
  padding: 100px 30px 300px;

  @media (max-width: 800px) {
    padding: 50px 30px 200px;
  }

  &:before {
    --height: 15vh;
    content: '';
    width: 100%;
    height: var(--height);
    position: absolute;
    top: calc(var(--height) * -1);
    left: 0;
    background-image: linear-gradient(
      to top,
      rgba(244, 243, 239, 1),
      rgba(244, 243, 239, 0)
    );
    pointer-events: none;
  }
`;

const DefinitionWrapper = styled(Box)<{ first?: boolean; isMobile?: boolean }>`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  overflow: visible !important;
  /* margin-bottom: ${p =>
    p.first ? '-550px' : p.first && p.isMobile ? '0' : '-350px'} !important; */
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    height: 100%;
  }

  .title-wrapper {
    overflow: hidden;
  }

  h2 {
    font-size: 60px;
    line-height: 1;
    font-family: 'NoeDisplayRegularItalic', Georgia, serif;
    font-style: normal;
    font-weight: normal;
    margin: 0;

    @media (max-width: 800px) {
      font-size: 50px;
    }

    @media (max-width: 600px) {
      margin-bottom: 8px;
    }
  }

  hr {
    width: 100%;
    border: 0;
    margin-bottom: 0;
    height: 1px;
    background: rgba(20, 48, 48, 0.5);
    transform-origin: left center;

    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }
`;

const DefinitionText = styled.div`
  font-size: 20px;
  line-height: 1.7;

  @media (max-width: 600px) {
    font-size: 16px;
  }

  @media (max-width: 400px) {
    max-width: 28ch;
  }

  .subtitle {
    font-style: italic;
  }
`;

const Definition = forwardRef<
  BoxProps,
  { item: any; first?: boolean; isMobile?: boolean }
>(({ item, first }, ref) => {
  return (
    <DefinitionWrapper ref={ref} first={first}>
      <div className="definition">
        <HeaderWrapper>
          <div className="title-wrapper">
            <h2>{item.title}</h2>
          </div>
          <img
            src={item.pronunciation.fluid.src}
            alt={item.pronunciation.alt}
            className="fade-in-up"
          />
          <hr className="fade-in-up" />
        </HeaderWrapper>

        <DefinitionText>
          <p className="subtitle fade-in-up">{item.subtitle}</p>
          <div
            className="fade-in-up"
            dangerouslySetInnerHTML={{ __html: item.body.html }}
          />
        </DefinitionText>
      </div>
    </DefinitionWrapper>
  );
});

const AngelsOverview: React.FC<any> = ({ data }) => {
  const { definition } = data;
  const container = useRef(null);
  const definitions = useRef<any[]>([]);

  function createScrollTrigger(el: any) {
    const divider = el.querySelectorAll('hr');
    const contentElements = el.querySelectorAll('.fade-in-up');

    const splitText = new SplitText(el.querySelectorAll('h2'), {
      type: 'words',
    });
    const words = splitText.words;

    const tl = gsap.timeline({
      defaults: { duration: 0.4, ease: 'power4.out' },
    });

    tl.set(words, {
      opacity: 0,
    });

    contentElements.forEach((el: any) => {
      tl.set(el, {
        opacity: 0,
        y: 20,
      });
    });

    function onEnter() {
      tl.fromTo(
        words,
        {
          yPercent: 100,
        },
        {
          opacity: 1,
          yPercent: 0,
          stagger: 0.07,
          duration: 0.5,
        }
      )
        .fromTo(
          divider,
          {
            scaleX: 0,
          },
          {
            scaleX: 1,
          },
          '<.1'
        )
        .fromTo(
          contentElements,
          {
            y: 20,
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            stagger: 0.06,
          },
          '<'
        );
    }

    function onEnterBack() {
      tl.fromTo(
        words,
        {
          opacity: 0,
          yPercent: -100,
        },
        {
          yPercent: 0,
          opacity: 1,
          stagger: 0.04,
        }
      ).fromTo(
        contentElements,
        {
          y: -20,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          stagger: 0.05,
        },
        '<'
      );
    }

    function onLeave() {
      tl.to(words, {
        opacity: 0,
      }).to(
        contentElements,
        {
          opacity: 0,
        },
        '<'
      );
    }

    function onLeaveBack() {
      tl.fromTo(
        words,
        {
          yPercent: 0,
          opacity: 1,
        },
        {
          opacity: 0,
          yPercent: 100,
          stagger: 0.04,
        }
      ).to(
        contentElements,
        {
          opacity: 0,
          stagger: 0.05,
        },
        '<'
      );
    }

    ScrollTrigger.create({
      trigger: el,
      start: 'center center',
      pin: true,
      end: isMobile ? '+=800' : '+=950',
      onEnter: onEnter,
      onLeave: onLeave,
      onEnterBack: onEnterBack,
      onLeaveBack: onLeaveBack,
    });
  }

  useEffect(() => {
    if (isBrowser) {
      gsap.registerPlugin(ScrollTrigger, SplitText);
    }

    createScrollTrigger(definitions.current[0]);
    createScrollTrigger(definitions.current[1]);
  }, []);

  return (
    <Container ref={container}>
      <Definition
        ref={el => definitions.current.push(el)}
        item={definition[0]}
        isMobile={isMobile}
        first
      />
      <Definition
        ref={el => definitions.current.push(el)}
        item={definition[1]}
        isMobile={isMobile}
      />
    </Container>
  );
};

export default AngelsOverview;
